.color-picker,
.color-picker::before,
.color-picker::after,
.color-picker *,
.color-picker *::before,
.color-picker *::after {
  box-sizing: border-box;
}
.color-picker {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, .5);
}
.color-picker-container {
  direction: rtl;
  display: flex;
  height: 10em;
  border: 1px solid #000;
  color: #000;
}
.color-picker-container * {
  border-color: inherit;
  color: inherit;
}
.color-picker i {
  font: inherit;
  font-size: 12px; /* measure the color control size by measuring the text size */
}
.color-picker-h,
.color-picker-sv {
  direction: ltr;
  background-size: 100% 100%;
  position: relative;
}
.color-picker-h {
  width: 1.5em;
  border-left: 1px solid;
  background: transparent url('../static/cp/color-picker-h.png') no-repeat 50% 50%;
  background-image: linear-gradient(to top, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  cursor: ns-resize;
  overflow: hidden;
}
.color-picker-h i {
  display: block;
  height: .5em;
  position: absolute;
  top: -.25em;
  right: 0;
  left: 0;
  z-index: 3;
}
.color-picker-h i::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border:.25em solid;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.color-picker-sv {
  width: 10em;
  background: transparent url('../static/cp/color-picker-sv.png') no-repeat 50% 50%;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)), linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  cursor: crosshair;
}
.color-picker-sv i {
  display: block;
  width: .8em;
  height: .8em;
  position: absolute;
  top: -.4em;
  right: -.4em;
  z-index: 3;
}
.color-picker-sv i::before,
.color-picker-sv i::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid;
  border-color: inherit;
  border-radius: 100%;
}
.color-picker-sv i::before {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-color: #fff;
}
.color-picker-h,
.color-picker-sv {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}