@import "vars";

body {
	height: inherit;
	overflow: hidden;
	position: relative;
	width: inherit;
}

#page {
	display: flex;
	flex-direction: column;
	height: inherit;
	overflow: hidden;
	position: relative;
	width: inherit;
	#header-container, #footer-container {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		overflow: hidden;
		position: relative;
	}
	#header-container {
		max-height: 100px;
		padding: 0 $default_spacing;
		border-bottom: 2px solid $grey;
		#header-flex-container {
			display: flex;
			flex-direction: row;
			overflow-x: auto;
			overflow-y: hidden;
			position: relative;
			flex-grow: 1;
		}
		#header-left-container, #header-right-container {
			//line-height: 100px;
		}
		#header-right-container {
			div {
				padding-right: $default_spacing;
				&:last-child {
					padding-right: 0;
				}
				a {
					i {
						font-size: 34px;
					}
				}
			}
		}
	}
	#footer-container {
		max-height: 26px;
		padding: 0 calc(#{$default_spacing} / 2);
		#footer-left-container, #footer-right-container {
			line-height: 26px;
		}
	}
	#header-left-container, #header-right-container, #footer-left-container, #footer-right-container {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		position: relative;
	}
	#header-right-container, #footer-right-container {
		margin-left: auto;
	}
	#main-container {
		display: flex;
		flex-direction: row;
		//flex-grow: 1; //Ne fonctionne pas avec `overflow: hidden;`
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
		#main-left-container, #main-center-container, #main-right-container {
			overflow: hidden;
			position: relative;
		}
		#main-left-container, #main-right-container {
			flex-shrink: 0;
		}
		#main-center-container {
			display: flex;
			flex-direction: column;
			//flex-grow: 1; //Ne fonctionne pas avec `overflow: hidden;`
			height: 100%;
			width: 100%;
			#center-header-container {
				display: flex;
				flex-direction: row;
				flex-shrink: 0;
				max-height: 64px;
				overflow: hidden;
				padding: 0 $default_spacing;
				position: relative;
				#center-header-left-container, #center-header-right-container {
					display: flex;
					flex-direction: row;
					flex-shrink: 0;
					position: relative;
				}
				#center-header-right-container {
					margin-left: auto;
				}
			}
			#center-body-container {
				height: 100%;
				overflow: hidden;
				position: relative;
				width: 100%;
			}
			#center-action-container {
				align-items: flex-end;
				bottom: 0;
				display: flex;
				flex-direction: column;
				padding: 0 0 $default_spacing $default_spacing;
				position: absolute;
				left: 0;
				z-index: 10;
				button {
					margin-bottom: $default_spacing;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.action-container {
					margin-top: $default_spacing;
					&:first-child {
						margin-top: 0;
					}
				}
			}
		}
	}
}