html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	height: 100vh;
	line-height: 1.15;
	width: 100vw;
}

*, *:after, *:before {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
	margin: 0;
	padding: 0;
}

div, textarea, table, td, th, code, pre, samp {
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-o-hyphens: auto;
	hyphens: auto;
}

pre, code, kbd, samp {
	font-family: monospace, monospace;
	font-size: 1em;
	white-space: pre-wrap;
}

button, input, optgroup, select, textarea {
	margin: 0;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	td {
		padding: 0;
	}
}

a {
	color: inherit;
	text-decoration: none;
	&:visited, &:hover, &:active, &:focus {
		color: inherit;
	}
}

img {
	max-width: 100%;
	height: auto;
}