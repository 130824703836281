$default_spacing: 20px;
$default_spacing_mobile: 10px;
/*-- Color --*/
$white: #ffffff;
$white_smoke: #f5f5f5;
$light_grey: #aaaaaa;
$grey: #a6a6a6;
$dark_grey: #717171;
$black: #000000;
$light_blue: #2196f3;
$color_theme: #377e6a;
$mdc-error: #b00020;

$w10: 10%;
$w25: 25%;
$w50: 50%;
$w100: 100%;