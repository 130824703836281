@import "vars";
/* FONTS */
@font-face {
	font-family: "openSans";
	src: local(OpenSans Regular), url("../static/fonts/OpenSans-Regular.woff") format("woff");
	font-weight: 400;
}

@font-face {
	font-family: "openSansBold";
	src: local(OpenSans Bold), url("../static/fonts/OpenSans-Bold.woff") format("woff");
	font-weight: 700;
}

@font-face {
	font-family: "openSansLight";
	src: local(OpenSans Light), url("../static/fonts/OpenSans-Light.woff") format("woff");
	font-weight: 300;
}


html {
	color: $dark_grey;
	font-family: 'Roboto', Helvetica, sans-serif;
	font-size: 16px;
}

* {
	outline: 0;
}

#page {
	.alert-danger {
		background: red;
		padding: 10px;
		margin: 10px 0;
		border-radius: 25px;
		color: $white;
	}

	/* --- Toast --- */
	#toast-container {
		background-color: grey;
		border-radius: 25px;
		max-width: 600px;
		overflow: hidden;
		position: absolute;
		top: -50%;
		z-index: 1000;
		transition: top 150ms;
		.toast {
			color: $white;
			font-weight: 400;
			padding: 0 15px;
			line-height: 50px;
			width: 100%;
			&.green {
				background-color: $color_theme;
			}
			&.red {
				background-color: $mdc-error;
			}
			&.orange {
				background-color: #e58e32;
			}
			&.white {
				background-color: $white;
			}
		}
		&.open {
			right: 100px;
			top: 100px;
			transition: top 250ms;
		}
	}

	#access-block-container {
		background-color: $white;
		height: 100%;
		opacity: 0.6;
		position: absolute;
		width: 100%;
		z-index: 1000;
	}

	#alert-access {
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 1000;

		div {
			color: $color_theme;

			i {
				font-size: 7em;
			}

			.alert-message {
				font-size: 23px;

				button {
					margin-top: $default_spacing;
				}
			}

			&:first-child {
				position: relative;
				transform: translate(-50%, -50%);
				text-align: center;
				left: 50%;
				top: 50%;
				width: 550px;
			}
		}
	}

	#header-container {
		height: 100px;

		#header-left-container {
			border-right: 2px solid $grey;
			flex-direction: column;
			padding: 15px 40px;
			width: calc(300px - #{$default_spacing});

			> img {
				margin-bottom: $default_spacing_mobile;
				width: 140px;
			}
		}

		#header-right-container {
			color: $color_theme;
			text-align: center;
			padding: $default_spacing 0;

			.app-flex-column {
				> i {
					font-size: 30px;
					margin-bottom: $default_spacing_mobile;
				}
			}
		}
	}

	#app-left-personnalisation {
		border-right: 2px solid $grey;
		height: 100%;
		width: 300px;

		.digital-printing-container {
			.app-file-bot, .app-file-top {
				border-radius: 50%;
				margin: 0;
				width: 100%;
				height: inherit;
				z-index: 10;

				> div {
					line-height: 150px;
				}
			}
		}

		.finishing-container {
			.choice-finishing {
				flex-direction: column;
				padding-left: $default_spacing_mobile;

				.mdc-form-field {
					&:first-child {
						margin-top: $default_spacing_mobile;
					}

					> label {
						color: $dark_grey;
						font-size: 12px;
					}

				}
			}
		}

		.couvercle-color-container {
			display: flex;
			flex-direction: row;
			margin: $default_spacing 0;
			position: relative;

			.delete-mask {
				cursor: pointer;
				line-height: 50px;
				font-size: 30px;
				margin-left: 20px;
			}

			.couvercle-color {
				background-color: $color_theme;
				border: 1px solid $grey;
				border-radius: 50%;
				cursor: pointer;
				height: 50px;
				width: 50px;
			}

			.couvercle-mask {
				position: relative;
				border: 1px dashed $grey;
				border-radius: 50%;
				height: 50px;
				width: 50px;

				> input {
					position: absolute;
					cursor: pointer;
					height: 100%;
					width: 100%;
					border-radius: 50%;
					opacity: 0;
				}
			}
		}

		.app-upload-mask {
			display: flex;
			flex-direction: column;

			.app-position {
				flex-grow: 1;
			}
		}

		.tier-color-container {

			display: flex;
			flex-direction: row;
			margin: $default_spacing 0;

			[class*=app-link] {
				cursor: pointer;
				line-height: 36px;
				padding: 0 15px;
			}

			.tier-top {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}

			.tier-middle {
				bottom: 11px;
			}

			.tier-bottom {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				bottom: 0;
			}

			[class*=tier-color] {
				border: 1px solid $grey;
				border-radius: 10px;
				cursor: pointer;
				height: 36px;
				margin-bottom: $default_spacing_mobile;
				position: relative;
				width: 50px;

				> button {
					position: absolute;
					opacity: 0;
					z-index: 10;
				}

				.tier {
					border: 1px solid #a6a6a6;
					height: 12px;
					position: absolute;
					width: 100%;
				}
			}

			.tier-masks {
				height: 36px;
				margin-bottom: $default_spacing_mobile;
				position: relative;
				width: 50px;

				> input {
					cursor: pointer;
					position: absolute;
					height: 100%;
					width: 100%;
					border-radius: 50%;
					opacity: 0;
					z-index: 10;
				}

				.tier {
					border: 1px dashed $grey;
					height: 12px;
					position: absolute;
					width: 100%;
				}
			}
		}
	}

	#center-body-container {
		#access-block-container {
			background-color: $dark_grey;
			height: 100%;
			opacity: 0.6;
			position: absolute;
			width: 100%;
			z-index: 1000;
		}

		.app-edit-container {
			background-color: $white;
			position: relative;
			transform: translate(-40%, -50%);
			text-align: center;
			left: 50%;
			top: 50%;
			width: 550px;
			z-index: 1000;
		}
	}

	.checkbox-moulure {
		color: $dark_grey;
		font-size: 12px;
		padding: 5px 0 0 $default_spacing;
		border-left: 5px solid $light_grey;
		border-right: 5px solid $light_grey;
	}

	// Class récurante
	.action-form {
		> button {
			&:first-child {
				margin-right: $default_spacing;
			}

			flex-grow: 1;
		}
	}

	.app-bold {
		font-family: 'Roboto', Helvetica, sans-serif;
		font-weight: bold;
	}

	.app-upc {
		text-transform: uppercase;
	}

	.app-box {
		border: 1px solid $light_grey;
		border-radius: 5px;
		box-shadow: 0 0 5px -1px rgba($black, 0.2);
	}

	.app-banner-security {
		padding: $default_spacing;
		width: 500px;
		position: relative;
		transform: translate(-40%, -50%);
		text-align: center;
		left: 50%;
		top: 50%;

		.app-form {
			padding-bottom: $default_spacing;
		}

		.mdc-text-field {
			width: 100%;
		}
	}

	.app-title-menu {
		color: $white;
		line-height: 40px;
		padding-left: 20px;
	}

	.app-color-theme {
		color: $color_theme;
	}

	.app-flex-column {
		display: flex;
		flex-direction: column;
	}

	.app-title {
		font-size: 28px;
		font-family: 'Roboto', Helvetica, sans-serif;
		color: $color_theme;
		text-transform: uppercase;
		padding-bottom: $default_spacing;
	}

	.app-flex-row {
		display: flex;
		flex-direction: row;

		.mdc-form-field {
			label {
				line-height: 3;
				font-family: 'Roboto', Helvetica, sans-serif;
				color: $dark_grey;
			}
		}
	}

	.file-active {
		border: 2px solid $color_theme !important;
	}

	.app-couvercle-upload {
		position: relative;
		display: flex;
		flex-direction: row;
		margin: 20px auto;
		width: 150px;
		height: 150px;

		> img {
			position: absolute;
			height: inherit;
		}
	}

	.app-file {
		border: 1px dashed #a6a6a6;
		border-radius: 10px;
		height: 100px;
		margin: $default_spacing 0;
		position: relative;

		> input {
			cursor: pointer;
			height: 100%;
			opacity: 0;
			position: absolute;
			width: 100%;
			z-index: 10;
		}

		> img {
			height: inherit;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		> div {
			color: $grey;
			line-height: 100px;
			position: absolute;
			text-align: center;
			width: 100%;
		}
	}

	.app-security {
		font-size: 14px;
		margin-bottom: $default_spacing;
		&:first-child {
			flex-grow: 1;
			text-align: start;
		}
		a {
			cursor: pointer;
		}
	}
	.app-little-size {
		font-size: 14px;
	}

	[class*=app-link] {
		font-size: 25px;
	}

	.app-position {
		color: $grey;
		text-align: center;
	}

	.app-radient-container {
		background: linear-gradient(0.25turn, #377e6a, #FFFFFF);
		height: 40px;
	}

	[class*=app-content] {
		display: none;
		font-size: 12px;
		padding: 0 $default_spacing;
	}

	#main-center-container {
		&:not(.on-stand-icon) {
			#center-body-container {
				.in-stand-icon {
					display: none;
				}
			}
		}

		&.on-stand-icon {
			&:not(.center-extra-open) {
				#center-header-left-container {
					> div:not(:first-child) {
						display: none;
					}
				}
			}
		}

		&.center-extra-open {
			#center-extra-container {
				left: 0;
				transition: left 250ms;
			}

			#center-header-container {
				transition: background-color 250ms;
			}
		}
	}

	#center-extra-container {
		background-color: $white;
		height: 100%;
		left: -105%;
		overflow: hidden;
		position: absolute;
		width: 100%;
		z-index: 15;
		transition: left 150ms;

		[class*=app-extra-frame] {
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			padding: $default_spacing;
			position: absolute;
			right: -105%;
			width: 100%;
			z-index: 16;
			transition: right 150ms;

			&.show {
				right: 0;
				z-index: 17;
				transition: right 250ms;
			}
		}
	}

	.app-content--active {
		display: block;
	}

	.app-drive-input-datetime {
		border: 2px solid #377e6a;
		border-radius: 4px;
		color: $dark_grey;
		font-size: 16px;
		font-family: 'Roboto', Helvetica, sans-serif;
		margin-bottom: $default_spacing;
		padding: 4px 6px;
	}

	.app-upload-mask {
		> i {
			cursor: pointer;
			color: $dark_grey;
			font-size: 30px;
			padding: 0 10px;
		}
	}

	.app-info-bulle {
		position: absolute;
		width: 350px;
		height: 90px;
		left: 120px;
		background: $color_theme;
		font-size: 16px;
		font-family: 'Roboto', Helvetica, sans-serif;
		color: #fff;
		padding: 10px;
		border-radius: 10px;
		text-align: center;
		&::after {
			content: "";
			border-right: 40px solid $color_theme;
			border-top: 20px solid rgba(0, 0, 0, 0);
			border-bottom: 20px solid rgba(0, 0, 0, 0);
			position: absolute;
			left: -30px;
			top: 35px;
		}
	}

	.tab-fut {
		padding: 0 15px;
		.mdc-tab__content {
			font-size: 14px;
		}
	}

	.part-dp {
		display: none;
	}

	.hidden {
		display: none !important;
	}

	.visible {
		display: flex;
	}

	.convertisseur-online {
		font-size: 13px;
		font-family: 'Roboto', Helvetica, sans-serif;
		color: $color_theme;
		text-align: center;
		margin-top: $default_spacing;
	}

	.radio-straight-body {
		margin-left: $default_spacing;
	}

	.background-grey {
		background-color: $dark_grey;
	}

	// material rework
	.mdc-button--outlined:not(:disabled) {
		border-color: $color_theme;
	}

	.mdc-button:not(:disabled) {
		color: $color_theme;
		font-family: 'Roboto', Helvetica, sans-serif;
	}

	.mdc-button::before, .mdc-button::after {
		background-color: $color_theme;
	}

	#app-left-personnalisation {
		.mdc-tab-bar {
			margin-bottom: $default_spacing;
		}

		.mdc-tab-indicator .mdc-tab-indicator__content--underline {
			border-top-width: 2px;
		}

		.mdc-tab__content {
			font-size: 12px;
			font-family: 'Roboto', Helvetica, sans-serif;
			text-transform: none;
		}

		.mdc-tab {
			padding: 0 $default_spacing_mobile;
			width: 80px;
		}
	}

	.mdc-tab {
		width: 100%;
	}

	.mdc-ripple-upgraded--background-focused {
		background: none;
	}

	.mdc-tab__content {
		font-size: 16px;
		font-family: 'Roboto', Helvetica, sans-serif;
	}

	.mdc-tab-indicator .mdc-tab-indicator__content--underline {
		border-color: $color_theme;
		border-top-width: 4px;
	}

	.mdc-tab__text-label {
		color: $grey;
	}

	.mdc-tab__ripple::before, .mdc-tab__ripple::after {
		background-color: $white;
	}

	.mdc-tab--active .mdc-tab__text-label {
		color: $color_theme;
	}

	.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
		border-color: $color_theme;
	}

	.mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
		border-color: $color_theme;
	}

	.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
		border-color: $color_theme;
		background-color: $color_theme;
	}

	.mdc-fab {
		background-color: $color_theme;
	}

	.mdc-notched-outline__trailing, .mdc-notched-outline__notch, .mdc-notched-outline__leading {
		border-color: $color_theme;
	}

	.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
		color: $color_theme;
	}

	.mdc-data-table__cell, .mdc-data-table__header-cell {
		font-family: 'Roboto', Helvetica, sans-serif;
	}

	.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
		border-color: $color_theme;
	}

	.form-active {
		margin: $default_spacing 0;
	}

	.mdc-form-field {
		> label {
			font-family: 'Roboto', Helvetica, sans-serif;
			color: $dark_grey;
			font-size: 16px;
		}
	}


	//	picker couleur
	.pickr {
		position: absolute;
		opacity: 0;
		z-index: 10;
		height: 100%;
		width: 100%;
		border-radius: 50px;
	}

	.pcr-button {
		z-index: 10;
		height: 100%;
		width: 100%;
	}

	.control {
		font-family: 'Roboto', Helvetica, sans-serif;
		display: flex;
		position: relative;
		padding-left: 20px;
		padding-right: 20px;
		margin: 5px 0;
		padding-top: 3px;
		cursor: pointer;
		font-size: 16px;

		> label {
			margin-top: 2px;
		}

		> input {
			position: absolute;
			z-index: -1;
			opacity: 0;

		}

		.control_indicator {
			position: relative;
			top: 2px;
			margin-left: 20px;
			margin-bottom: 0px;
			right: 0px;
			height: 20px;
			width: 20px;
			border: 2px solid $color_theme;
			border-radius: 4px;
		}
	}

	.control-checkbox .control_indicator::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 4.5rem;
		height: 4.5rem;
		margin-left: -1.3rem;
		margin-top: -1.3rem;
		background: #2aa1c0;
		border-radius: 3rem;
		opacity: 0.6;
		z-index: 99999;
		transform: scale(0);
	}

	.control-checkbox input + .control_indicator::before {
		animation: s-ripple 250ms ease-out;
	}

	.control input:checked ~ .control_indicator {
		background: $color_theme;
	}

	.control input:checked ~ .control_indicator:after {
		display: block;
	}

	.control-checkbox .control_indicator:after {
		left: 6px;
		top: 2px;
		width: 3px;
		height: 8px;
		border: solid #ffffff;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}

	.control_indicator:after {
		box-sizing: unset;
		content: '';
		position: absolute;
		display: none;
	}

	/* -------------- Loader1 -------------- */
	.loader-app-left {
		border-right: 2px solid #a6a6a6;
		opacity: 0.7;
	}

	.loader-3d, .loader-app-left {
		z-index: 1000;

		.loader1 {
			position: relative;
			height: 80px;
			width: 80px;
			border-radius: 80px;
			border: 3px solid $color_theme;

			top: 28%;
			top: -webkit-calc(50% - 43px);
			top: calc(50% - 43px);
			left: 35%;
			left: -webkit-calc(50% - 43px);
			left: calc(50% - 43px);

			-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
			-webkit-animation: loader1 3s linear infinite;
			animation: loader1 3s linear infinite;
		}

		.loader1:after {
			content: "";
			position: absolute;
			top: -5px;
			left: 20px;
			width: 11px;
			height: 11px;
			border-radius: 10px;
			background-color: $color_theme;
		}

		@-webkit-keyframes loader1 {
			0% {
				-webkit-transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
			}
		}

		@keyframes loader1 {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

	}

}

